import fileTypeJson from "../translate/fileType.json";

export const fileType = {
  extensions: fileTypeJson.map(file => `.${file.name.split('_')[1]}`).join(', '),

  /**
   * Método para obtener la extensión de un archivo.
   * @param {string} fileName - El nombre del archivo.
   * @returns {string} - La extensión del archivo.
   */
  getExtension(fileName) {
    const extension = fileName?.split('.')[1];
    return extension;
  },

   /**
   * Método para obtener la descripción.
   * @param {string} lang - idioma.
   * @returns {string} - La descripción.
   */
  getDescription(lang, extension) {
    if (!extension) return;
    if (this.isValidExtension(extension)) {
      const file = fileTypeJson.find( file => file.name.split('_')[1] === extension);
      return file[lang];
    }

    return;
  },

  /**
   * Método para validar si la extensión existe en el archivo JSON.
   * @param {string} extension - La extensión del archivo.
   * @returns {boolean} true si la extensión existe, false si no.
   */
  isValidExtension(extension) {    
    if (!extension) return;
    return fileTypeJson.some(file => file.name.split('_')[1] === extension);
  }
};

