<template>
  <div class="right-sidebar">
    <button
      @click="showView(i)"
      v-for="(button, i) in filteredButtons"
      :key="i"
      :class="view === i ? 'sidebar-btn-bg-selected' : 'sidebar-btn-bg'"
    >
      <hover :content="langFilter(button)">
        <img
          :class="(i === 3 || i === 4) && 'sidebar-btn-img'"
          :src="
            i === 3 || i === 4
              ? require(`@/assets/img/right-sidebar/${button}.webp`)
              : require(`@/assets/img/right-sidebar/${button}.svg`)
          "
          :alt="button"
        />
      </hover>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "../../mixins/translationMixin.js";
export default {
  props: ["buttons", "view", "project_id", "lang"],
  data() {
    return {
      taskManager: "",
      traducciones: [
        {
          name: "taskManager",
          es: "Gestor de tareas",
          en: "Task Manager",
        },
        {
          name: "smartPrinting",
          es: "Smart Printing",
          en: "Smart Printing",
        },
      ],
    };
  },
  computed: {
    filteredButtons() {
      return this.buttons.filter(
        (button, i) => i !== 4 || this.taskManager !== ""
      );
    },
  },
  created() {
    axios
      .get(
        `${process.env.VUE_APP_API_URL}/myProjects/getProjectTaskManager/${this.project_id}`
      )
      .then((response) => {
        if (response.data) {
          const { task_manager_url } = response.data;
          this.taskManager = task_manager_url;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    showView(i) {
      if (i === 4) {
        window.open(this.taskManager, "_blank");
      }
      this.$emit("showView", i);
    },
  },
  mixins: [translationMixin],
};
</script>
