<template>
  <div class="mb-4 me-5 assistant-card">
    <div class="d-flex flex-column mx-4 my-3">
      <div class="dropdown ms-auto">
        <img
          class="pointer dropdown-toggle py-2 px-2"
          :src="require('@/assets/img/itinerario/options.svg')"
          :id="'dropdownMenuButton-' + assistant.id"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />
        <ul
          class="dropdown-menu"
          :aria-labelledby="'dropdownMenuButton-' + assistant.id"
        >
          <li>
            <a class="dropdown-item" @click="editAssistant">
              <img
                class="me-2"
                :src="require('@/assets/img/projects/edit.svg')"
              />
              {{ langFilter("configure") }}
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              :data-bs-toggle="'modal'"
              :data-bs-target="'#deleteModal-' + assistant.id"
            >
              <img
                class="me-2"
                :src="require('@/assets/img/projects/delete.svg')"
              />
              {{ langFilter("delete") }}
            </a>
          </li>
        </ul>
      </div>
      <div class="pointer d-flex flex-column" @click="viewDetails()">
        <div class="d-flex align-items-center">
          <img
            class="me-4"
            width="30"
            src="/img/icons/icon-robot-wink.svg"
            alt="Robot Wink"
          />
          <div class="fs-5 fw-bold text-truncate" style="color: #2a3b5a">
            {{ assistant.name }}
          </div>
        </div>

        <p class="description">{{ assistant.description }}</p>

        <hr />
        <div>
          <span>&#8226;</span> {{ langFilter("projects") }}:
          <strong>{{ assistant.projects_count }}</strong>
          <br />
          <span>&#8226;</span> {{ langFilter("templates") }}:
          <strong>{{ assistant.templates_count }}</strong>
          <br />
          <span>&#8226;</span> {{ langFilter("files") }}:
          <strong>{{ assistant.files_count }}</strong>
        </div>
        <div class="ms-auto text-muted pointer">
          {{ langFilter("see_more") }}
        </div>
      </div>
    </div>
  </div>

  <!-- Modal para eliminar -->
  <div
    class="modal fade"
    :id="'deleteModal-' + assistant.id"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="'deleteModalLabel-' + assistant.id"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content delete-modal" style="height: 420px">
        <div class="modal-body mx-4 d-flex flex-column justify-content-center">
          <h5 class="modal-title mx-auto mb-3 w-75">
            {{ langFilter("confirm_delete_assistant") }}
            <strong>{{ assistant.name }}</strong
            >?
          </h5>
          <p class="text-start mb-4">
            <span v-html="getDeleteAssistantWarning()"></span>
          </p>
          <div class="text-start mb-4">
            <p>{{ langFilter("assistant_present_in") }}</p>

            <ul class="list-unstyled">
              <li
                class="d-flex justify-content-between my-2"
                data-bs-dismiss="modal"
              >
                {{ langFilter("projects") }}:&nbsp;
                <strong>{{ assistant.projects_count }}</strong>
                <span
                  class="text-muted ms-auto pointer"
                  @click="viewDetails('projects')"
                >
                  {{ langFilter("see_more") }}</span
                >
              </li>
              <li
                class="d-flex justify-content-between my-2"
                data-bs-dismiss="modal"
              >
                {{ langFilter("templates") }}:&nbsp;
                <strong>{{ assistant.templates_count }}</strong>
                <span
                  class="text-muted ms-auto pointer"
                  @click="viewDetails('templates')"
                >
                  {{ langFilter("see_more") }}</span
                >
              </li>
              <li
                class="d-flex justify-content-between my-2"
                data-bs-dismiss="modal"
              >
                {{ langFilter("files") }}:&nbsp;
                <strong>{{ assistant.files_count }}</strong>
                <span
                  class="text-muted ms-auto pointer"
                  @click="viewDetails('files')"
                >
                  {{ langFilter("see_more") }}</span
                >
              </li>
            </ul>
          </div>
          <div class="d-flex mb-2 justify-content-center">
            <button
              v-if="!loading"
              type="button"
              class="text-muted border-0 bg-white py-2 px-3 mx-2"
              data-bs-dismiss="modal"
            >
              {{ langFilter("cancel") }}
            </button>

            <button
              v-if="!loading"
              type="button"
              class="border-0 rounded-pill text-white py-2 px-3 mx-2 bg-purpleStrong"
              @click="confirmDeleteAssistant"
            >
              {{ langFilter("confirm_delete") }}
            </button>
            <div v-else class="spinner-border text-primary mx-5" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import translationMixin from "@/mixins/translationMixin";
import translations from "./translate/AssistantTranslate.json";
import DOMPurify from "dompurify";

export default {
  name: "AssistantCard",
  props: ["assistant", "lang"],
  data() {
    return {
      loading: false,
      traducciones: translations,
    };
  },
  methods: {
    getDeleteAssistantWarning() {
      return DOMPurify.sanitize(this.langFilter('delete_assistant_warning'));
    },
    editAssistant() {
      this.$router.push({
        name: "EditAssistant",
        params: { id: this.assistant.id },
      });
    },
    confirmDeleteAssistant() {
      this.loading = true;

      axios
        .delete(
          `${process.env.VUE_APP_API_URL}/assistants/${this.assistant.id}`
        )
        .then((response) => {
          console.log("Asistente eliminado:", response.data);
          window.location.reload();
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al eliminar el asistente:", error);
        });
    },
    viewDetails(tab) {
      const query = tab ? { tab } : {};
      this.$router.push({
        name: "AssistantDetails",
        params: { id: this.assistant.id },
        query: query,
      });
    },
  },
  mixins: [translationMixin],
};
</script>
