<style scoped>
.go-back-button {
  cursor: pointer;
}

.assistants-tab .newProject-ProjectDataCategoryTitle {
  display: none !important;
}

.tabs-selector-label.disabled {
  cursor: default;
  pointer-events: none;
}
</style>

<template>
  <div style="padding-left: 3.5%">
    <div class="d-flex">
      <div @click="goBack" class="go-back-button">
        <img :src="require('@/assets/img/general/new_back.svg')" alt="Back" />
      </div>
      <header-general
        :title="langFilter('configureProject')"
        src="/img/sidebar/icon–projects.svg"
        class="ms-3"
      />
    </div>
  </div>

  <div v-if="loading">
    <img
      class="loading-rocket"
      :src="require('@/assets/img/general/loading.gif')"
    />
  </div>

  <div v-else-if="isUpdatingProject">
    <img
      :src="require('@/assets/img/projects/Tierra_Luna.png')"
      class="newProject-StartImage"
    />
    <h2 class="newProject-StartTitle">
      {{ langFilter("departing!") }}
    </h2>
    <p class="newProject-StartText" v-if="!isFromParts">
      {{ langFilter("email sent") }}
    </p>
  </div>

  <main v-else>
    <div class="newProjectV2-TabSelectorContainer">
      <div style="display: inline-block; margin: auto; text-align: center">
        <label
          class="tabs-selector-label"
          :class="[{ step: currentStep.id === id, disabled: isFromParts }]"
          v-for="({ id, name }, index) in labels"
          :key="id"
          @click="nextStepFunction(index)"
        >
          {{ langFilter(name) }}
        </label>
      </div>
    </div>

    <div v-if="isProjectTab">
      <config-project-section
        :lang="lang"
        :project="project"
        :taskManagerError="taskManagerError"
      />
    </div>

    <div v-if="isAssitantsTab" class="assistants-tab">
      <new-projects-assistant
        :templateExpert="selectedAssistants.expert.id"
        :templateEvaluator="selectedAssistants.evaluator.id"
        :lang="lang"
        :current-step="5"
        :assistantsList="assistants"
        @update-expert="updateAssistant('expert', $event)"
        @update-evaluator="updateAssistant('evaluator', $event)"
      />
    </div>

    <div v-if="isUsersTab">
      <itinerary-users
        v-model="users"
        @usersInvite="inviteUsers($event)"
        :lang="lang"
        :editing="true"
        :space="project.space"
        :project_id="project.id"
      />
    </div>

    <div
      class="newProjectV2-NextButton"
      :class="{ 'nextButton-clickable': isValidSubmission || isFromParts }"
      @click="buttonAction(currentStep.index + 1)"
    >
      <p class="newProject-ButtonText">
        {{ buttonText }}
      </p>
    </div>

    <confirmation-modal
      v-if="isOpenModal"
      :body="modalBody"
      @close-confirmation="closeConfirmation"
      @confirm="modalAction"
      :lang="lang"
      :full-width="isVisibleConfirmAssistantModal"
      :fixed="true"
    />
  </main>

  <alertMessage :lang="lang" ref="alerts"></alertMessage>
</template>

<script>
import axios from "axios";
import translationMixin from "../mixins/translationMixin";
import translations from "../components/projects/translate/NewProjectsV2Component.json";
import NewProjectsAssistantComponent from "@/components/projects/NewProjectsAssistantComponent.vue";
import ConfigProjectSection from "@/components/projects/ConfigProjectSection.vue";

const PARTS_NAME = "Parts";

export default {
  emits: ["openAlert"],
  props: ["lang", "origin", "homeNotifications", "spaces", "role"],
  components: {
    ConfigProjectSection,
    NewProjectsAssistant: NewProjectsAssistantComponent,
  },
  beforeMount() {
    if (this.origin.name) {
      localStorage.setItem("origin", JSON.stringify(this.origin));
    }

    this.originRoute = JSON.parse(localStorage.getItem("origin")) || {};

    const stepNumber = this.originRoute.name === PARTS_NAME ? 1 : 0;
    this.currentStep = { id: stepNumber, index: stepNumber };
  },
  async mounted() {
    const { project_id } = this.$route.params;

    if (!project_id) {
      return;
    }

    this.selectedProject = project_id;
    await this.getSelectedProject();
    await this.fetchAssistants();
  },
  beforeUnmount() {
    localStorage.removeItem("origin");
  },
  data() {
    return {
      originRoute: {},
      currentStep: { id: 0, index: 0 },
      labels: [
        { id: 0, name: "projectInformation" },
        { id: 1, name: "assistants" },
        { id: 2, name: "users" },
      ],
      selectedProject: null,
      loading: false,
      project: {},
      assistants: [],
      users: [],
      taskManagerError: false,
      errors: [],
      succesful: [],
      isValidProject: false,
      isVisibleConfirmationModal: false,
      isVisibleConfirmAssistantModal: false,
      isUpdatingProject: false,
      temporaryAssistant: {},
      selectedAssistants: {
        expert: {},
        evaluator: {},
      },
      traducciones: [
        ...translations,
        {
          name: "configureProject",
          es: "Configurar proyecto",
          en: "Configure project",
        },
        {
          name: "projectInformation",
          es: "Información del proyecto",
          en: "Project information",
        },
        {
          name: "saveChanges",
          es: "Guardar cambios",
          en: "Save changes",
        },
        {
          name: "theAssistant",
          es: "El Asistente",
          en: "The assistant",
        },
        {
          name: "willBeRemoved",
          es: "Será removido de la conversación y se asignará el nuevo Asistente",
          en: "Will be removed from the conversation, and the new Assistant ",
        },
        {
          name: "keepTheHistory",
          es: "Conservarás el historial en tu proyecto",
          en: "You will keep the history in your project",
        },
        {
          name: "changeTheAssistantAgain",
          es: "Y podrás volver a cambiarlo cuando quieras.",
          en: "And can change it again whenever you want.",
        },
        {
          name: "toTheConversation",
          es: "A la conversación",
          en: "To the conversation",
        },
      ],
    };
  },
  computed: {
    isOpenModal() {
      return (
        this.isVisibleConfirmationModal || this.isVisibleConfirmAssistantModal
      );
    },
    isProjectTab() {
      return this.currentStep.id === 0;
    },
    isAssitantsTab() {
      return this.currentStep.id === 1;
    },
    isUsersTab() {
      return this.currentStep.id === 2;
    },
    isValidSubmission() {
      const { name, startDate, endDate } = this.project;
      const allFieldsValid = [name, startDate, endDate].every((field) =>
        (field || "").trim()
      );

      const { tutors, members } = this.getUsersMembers;
      const usersValid =
        !this.isUsersTab || !!(tutors?.length || members?.length);

      return allFieldsValid && usersValid;
    },
    isFromParts() {
      return this.originRoute.name === PARTS_NAME;
    },
    isFinalStep() {
      return (
        this.isFromParts || this.currentStep.index === this.labels.length - 1
      );
    },
    buttonText() {
      return this.langFilter(this.isFinalStep ? "saveChanges" : "next");
    },
    modalBody() {
      if (!this.isOpenModal) return;

      if (this.isVisibleConfirmationModal) {
        return "areYouSureToExitFromConfig";
      }

      const typeName = this.getAssistantTypeName(this.temporaryAssistant);

      if (!typeName) {
        console.error("No se pudo cargar la información del asistente.");
        return;
      }

      const previousAssistant = this.findProjectAssistantByType(typeName) || {};
      const { name: previousAssistantName = "" } = previousAssistant;
      const { name: newAssistantName = "" } = this.temporaryAssistant;

      return this.getTranslation({ previousAssistantName, newAssistantName });
    },
    modalAction() {
      return this.isVisibleConfirmationModal
        ? this.goBack
        : this.confirmAssistant;
    },
    getUsersMembers() {
      const [tutors, members] = this.users;

      return {
        tutors,
        members,
      };
    },
  },
  methods: {
    getTranslation({ previousAssistantName, newAssistantName }) {
      return [
        this.langFilter("theAssistant"),
        previousAssistantName,
        this.langFilter("willBeRemoved").toLowerCase(),
        newAssistantName,
        `${this.langFilter("toTheConversation").toLowerCase()}.`,
        this.langFilter("keepTheHistory"),
        this.langFilter("changeTheAssistantAgain").toLowerCase(),
      ].join(" ");
    },
    buttonAction(index) {
      if (!this.isValidSubmission) {
        return;
      }

      if (this.isFinalStep) {
        this.updateProject();
        return;
      }

      this.nextStepFunction(index);
    },
    nextStepFunction(index) {
      if (!this.isValidUrl()) {
        return;
      }

      const invalidForwardMovement =
        index > this.currentStep.index && !this.isValidSubmission;

      if (this.isFromParts || invalidForwardMovement) {
        return;
      }

      this.currentStep = { id: index, index };
    },
    getValuesFromData({ assistants = [], project, users, program }) {
      const {
        id,
        name,
        start_date: startDate,
        end_date: endDate,
        task_manager_url: taskManager = "",
        space_id: spaceId,
      } = project;
      const { name: programName } = program;
      const { tutors, members } = users;

      assistants.forEach((assistant) => {
        this.selectedAssistants[assistant.assistant_type_name] = assistant;
      });

      this.users = [tutors, members];
      this.project = {
        id,
        name,
        programName,
        startDate,
        endDate,
        taskManager,
        space: {
          id: spaceId,
        },
        assistants,
      };
    },
    openErrorsAlert() {
      this.$refs.alerts.title = "Ups... Something went wrong!";
      this.$refs.alerts.text = "Please try again later";

      if (this.errors.length == 0) {
        this.errors.push("error");

        this.errorTime = {
          animationDuration: "12s",
          animationName: "timebar_progress_x",
        };
        this.setTimeouts = setTimeout(() => {
          this.errors = [];
          this.$refs.alerts.succesful = false;
        }, 12000);
      }
    },
    closeErrorsWindow() {
      this.errors = [];
    },
    closeConfirmation() {
      if (this.isVisibleConfirmationModal) {
        this.isVisibleConfirmationModal = false;
        return;
      }

      this.resetAssistant();
      this.isVisibleConfirmAssistantModal = false;
    },
    goBack() {
      if (!this.isVisibleConfirmationModal) {
        this.isVisibleConfirmationModal = true;
        return;
      }

      this.redirectToNextView();
    },
    isValidUrl() {
      const urlString = this.project?.taskManager;

      if (!urlString) {
        return true;
      }

      try {
        const url = new URL(urlString);

        const hostnamePattern = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
        const isValid = hostnamePattern.test(url.hostname);

        this.taskManagerError = !isValid;

        return isValid;
      } catch (error) {
        this.taskManagerError = true;
        console.error("Invalid URL", error);
        return false;
      } finally {
        if (this.taskManagerError) {
          this.openErrorsAlert();
        }
      }
    },
    redirectToNextView() {
      const defaultRoute = "MyProjects";

      const {
        name = defaultRoute,
        project_id,
        solution_id,
        station_id,
      } = this.originRoute;

      const route = { name };

      if (this.isFromParts) {
        route.params = { project_id, station_id, solution_id };
      }

      this.$router.replace(route);
    },
    updateAssistant(type, payload) {
      if (!payload) {
        this.selectedAssistants[type] = {};
        return;
      }

      this.temporaryAssistant = this.assistants.find(
        ({ id }) => id === payload
      );

      const previousAssistant = this.findProjectAssistantByType(type);
      const isSameAsPrevious = previousAssistant?.id === payload;

      if (!previousAssistant || isSameAsPrevious) {
        this.confirmAssistant();
        return;
      }

      this.selectedAssistants[type] = this.temporaryAssistant;
      this.isVisibleConfirmAssistantModal = true;
    },
    confirmAssistant() {
      const name = this.getAssistantTypeName(this.temporaryAssistant);

      if (!name) {
        console.error("Assistant type name is missing");
        return;
      }

      this.selectedAssistants[name] = this.temporaryAssistant;
      this.isVisibleConfirmAssistantModal = false;
    },
    getAssistantTypeName(assistant) {
      return assistant?.assistant_type?.name;
    },
    findProjectAssistantByType(typeName) {
      return this.project.assistants.find(
        ({ assistant_type_name: projectAssistantName }) =>
          projectAssistantName === typeName
      );
    },
    resetAssistant() {
      const type = this.getAssistantTypeName(this.temporaryAssistant);
      const originalAssistant = this.findProjectAssistantByType(type);

      this.selectedAssistants[type] = originalAssistant;
    },
    buildProjectPayload() {
      const { name, startDate, endDate, taskManager } = this.project;
      const { expert, evaluator } = this.selectedAssistants || {};
      const { tutors, members } = this.getUsersMembers;

      return {
        name,
        start_date: startDate,
        end_date: endDate,
        assistant_id: expert?.id || null,
        evaluator_id: evaluator?.id || null,
        tutors,
        members,
        taskManager,
      };
    },
    async updateProject() {
      this.isUpdatingProject = true;

      try {
        const payload = this.buildProjectPayload();

        const { data } = await axios.put(
          `${process.env.VUE_APP_API_URL}/projects/${this.selectedProject}`,
          payload
        );

        if (data.error) {
          this.openErrorsAlert();
          return;
        }

        this.redirectToNextView();
      } catch (error) {
        console.error(error);
        this.openErrorsAlert();
      } finally {
        this.isUpdatingProject = false;
      }
    },
    async getSelectedProject() {
      try {
        this.loading = true;

        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/projects/${this.selectedProject}`
        );

        if (!data) {
          return;
        }

        this.isValidProject = true;
        this.getValuesFromData(data);
      } catch (error) {
        this.openErrorsAlert();
        this.isValidProject = false;
        console.error("Error getting project", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchAssistants() {
      this.loading = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/assistants`
        );
        const { data } = response;

        this.assistants = data;
      } catch (error) {
        console.error("Error fetching assistants:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  mixins: [translationMixin],
};
</script>
